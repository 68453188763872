import React, { Component } from 'react';
import Article from './Article';
import './App.css';


class App extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      articles: {
        'article': {
          "color": "FEC006",
          "title": "Dara test 1",
          "logo" : "./svd.jpg",
          "category": "News",
          "excerpt": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan turpis ex, quis aliquet lectus dictum sed. Nullam cursus est quam, a egestas diam ultricies vel. Nunc ex ante, elementum ut felis vitae, varius venenatis dui.",
          "date": new Date()
        },
        'article-1': {
          "color": "FE5621",
          "title": "Dara test 2",
          "logo" : "./dn.gif",
          "category": "News",
          "excerpt": "Praesent lacus neque, accumsan ut volutpat non, ullamcorper eu libero. Cras mollis libero vitae finibus egestas. Fusce rutrum libero eu elit imperdiet iaculis.",
          "date": new Date()
        },
        'article-2': {
          "color": "673AB7",
          "title": "Dara test 3",
          "logo" : "./sr.jpg",
          "category": "News",
          "excerpt": "Curabitur quis facilisis lectus. Nullam ac nisl sed felis faucibus pretium sed nec lacus. Duis pellentesque vestibulum eros in rhoncus.",
          "date": new Date()
        },
        'article-3': {
          "color": "2196F3",
          "title": "Dara test 4",
          "logo" : "./svt.jpg",
          "category": "News",
          "excerpt": "Aenean convallis consequat velit, at volutpat enim lacinia nec. Vivamus ut dui et diam venenatis mollis. Aliquam hendrerit eros sed convallis faucibus.",
          "date": new Date()
        },
        'article-4': {
          "color": "2196F3",
          "title": "Dara test 5",
          "logo" : "./expressen.png",
          "category": "News",
          "excerpt": "Nulla quis mi at tortor tincidunt laoreet. Proin porttitor vitae ex a rutrum. Phasellus tortor odio, feugiat in massa at, cursus rutrum ante.",
          "date": new Date()
        },
        'article-5': {
          "color": "2196F3",
          "title": "Dara test 6",
          "logo" : "./aftonbladet.jpg",
          "category": "News",
          "excerpt": "Aenean convallis consequat velit, at volutpat enim lacinia nec. Vivamus ut dui et diam venenatis mollis. Aliquam hendrerit eros sed convallis faucibus.",
          "date": new Date()
        }
      }
    };
  };


  fetchQuotes = () => {
    fetch("./data.json")
      .then(response => response.json())
      .then(result => this.setState({articles: result.entries.sort((a, b) => (''+b.time).localeCompare(a.time))}))
      .catch(e => console.log(e));
  };

  componentDidMount() {
    this.fetchQuotes()
    this.timer = setInterval(() => this.fetchQuotes(), 60000);
  };

  renderArticle(key) {
    return (
      <div className="column">
        <Article key={key} index={key} details={this.state.articles[key]} />
      </div>
    );
  };

  render() {
    return (
      <div className="App">
        <div className="container">
            {Object.keys(this.state.articles).map(this.renderArticle.bind(this))}
          </div>
      </div>
    );
  }
}

export default App;

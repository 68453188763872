import React, { Component } from 'react';
import './Article.scss';

class Article extends Component {



  render() {

    function handleClick(e) {
      e.preventDefault();
      window.open(this.props.details.url, "_blank")
    }

    function logoFromSrc(src){
      if(src === "svd") return "./svd.jpg";
      if(src === "dn") return "./dn.gif";
      if(src === "sr") return "./sr.jpg";
      if(src === "svt") return "./svt.jpg";
      if(src === "expressen") return "./expressen.png";
      return "./aftonbladet.jpg"
    }

    var details = this.props.details;
    
    return (
      <article className="article" onClick={handleClick.bind(this)}>
        <img className="articleLogo" src={logoFromSrc(details.src)} />
        <h2 className="article__title">{details.title}</h2>
        <p className="article__excerpt">{details.descr}</p>
        <span className="article__time">{details.time}</span>
      </article>
    );
  }

}

export default Article;